import { ObjectShape } from "yup/lib/object";
import moment, { Moment } from "moment";

export type Modify<R, T> = Omit<R, keyof T> & T;

// This is used to strongly type the form data, for example:
// const schema = yup.object().shape<YupShape<{name: string, surname: string}>>({name: yup.string(), surname: yup.string()});
type ObjectShapeValues = ObjectShape extends Record<string, infer V>
  ? V
  : never;
export type YupShape<T extends Record<any, any>> = Record<
  keyof T,
  ObjectShapeValues
>;

export type ObjectIdFe = string;

export class JsUtility {
  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  static isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  static mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (JsUtility.isObject(target) && JsUtility.isObject(source)) {
      for (const key in source) {
        if (JsUtility.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          JsUtility.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return JsUtility.mergeDeep(target, ...sources);
  }

  /**
   * Given an object obj {} and a string of separated for keys by dots
   * like "key1.key2.key3", it will return the object property under obj.key1.key2.key3
   * @param obj
   * @param str
   */
  static accessObjectByDotSeparatedKeys(obj: Object, str: string) {
    return str
      .split(".")
      .reduce((o, i) => (o ?? {})?.[i] ?? (o ?? {})?.[parseInt(i, 10)], obj);
  }

  static formatDateCET(
    date: string | Moment | Date,
    format: string = "DD/MM/YYYY"
  ) {
    // Matches dates in the format DD/MM/YYYY
    const standardDateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    const parseFormat = typeof date === "string" && standardDateRegex.test(date) ? "DD/MM/YYYY" : undefined;
    return moment(date, parseFormat).utcOffset("+0100").format(format);
  }
}

export const italianPrepositions = [
  "il",
  "lo",
  "la",
  "i",
  "gli",
  "le",
  "di",
  "a",
  "da",
  "in",
  "con",
  "su",
  "per",
  "tra",
  "fra",
  "dello",
  "del",
  "degli",
  "delle",
  "e",
  "o",
  "alle",
  "agli",
  "alle",
  "dell",
  "all",
];
