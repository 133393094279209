import { FC, memo, PropsWithChildren, useReducer } from "react";
import { PageDataContext, PageDataContextType } from "../index";
import { MenuItem, PrivacyDisclaimer } from "models/common/StaticContents";
import { ParentPage } from "models/client/PageFe";
import { IPressReleaseCategoryFe } from "models/client/PressReleaseCategoryFe";
import { IPressReleaseFe } from "models/client/PressReleaseFe";
import { UserProfile } from "models/common/UserCommon";
import { IArticleFe } from "models/client/ArticleFe";
import { FooterProps } from "components/_visual-components";

const defaultState = {
  footerContent: { columns: [] },
  menuData: [],
  breadcrumbs: [],
  pressReleaseCategories: [],
  pressReleases: [],
  privacyDisclaimer: null,
  slug: null,
};

type PageDataContextProviderProps = {
  menuData?: MenuItem[];
  footerContent?: FooterProps;
  breadcrumbs?: ReadonlyArray<ParentPage>;
  pressReleaseCategories?: IPressReleaseCategoryFe[];
  pressReleases?: IPressReleaseFe[];
  userProfile?: UserProfile;
  mediumCardsArticles?: IArticleFe[];
  privacyDisclaimer?: PrivacyDisclaimer;
  slug?: string;
};

const pageDataReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const PageDataContextProvider: FC<
  PropsWithChildren<PageDataContextProviderProps>
> = memo(
  ({
    children,
    menuData,
    footerContent,
    breadcrumbs,
    pressReleaseCategories,
    pressReleases,
    userProfile,
    mediumCardsArticles,
    privacyDisclaimer,
    slug,
  }) => {
    const [pageDataState, dispatchPageDataAction] = useReducer(
      pageDataReducer,
      {
        menuData: menuData ?? defaultState.menuData,
        footerContent: footerContent ?? defaultState.footerContent,
        pressReleaseCategories:
          pressReleaseCategories ?? defaultState.pressReleaseCategories,
        pressReleases: pressReleases ?? defaultState.pressReleases,
        privacyDisclaimer: privacyDisclaimer ?? defaultState.privacyDisclaimer,
        breadcrumbs: breadcrumbs ?? defaultState.breadcrumbs,
        slug: slug ?? defaultState.slug,
      }
    );

    const pageDataContext: PageDataContextType = {
      pressReleases: pressReleases,
      pressReleaseCategories: pressReleaseCategories,
      mainMenu: menuData,
      footerContent: footerContent,
      breadcrumbs,
      userProfile,
      mediumCardsArticles,
      privacyDisclaimer,
      slug,
    };

    return (
      <PageDataContext.Provider value={pageDataContext}>
        {children}
      </PageDataContext.Provider>
    );
  }
);
