import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextFieldProps } from "@mui/material";
import React, { memo } from "react";
import { useFormDatePicker } from "components/_form/FormDatePicker/index.hooks";
import moment, { Moment } from "moment";
import "moment/locale/it";

moment.locale("it");

interface OmittedProps {
  renderInput: any;
  onChange: any;
  value: any;
}

type FormDatePickerProps = {
  name: string;
  label: string;
  helperText?: string;
  index?: number;
  textFieldProps?: TextFieldProps;
} & Omit<DatePickerProps<Moment>, keyof OmittedProps>;

export const FormDatePicker = memo(
  ({
    name,
    label,
    helperText,
    minDate,
    maxDate,
    textFieldProps,
  }: FormDatePickerProps) => {
    const { fieldValue, setValue, error } = useFormDatePicker({ name });

    return (
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"it"}>
        <DatePicker
          minDate={minDate ? moment(minDate) : undefined}
          maxDate={maxDate ? moment(maxDate) : undefined}
          label={label}
          value={fieldValue}
          onChange={(date) => setValue(date)}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              error: !!error,
              helperText: error || helperText,
              ...textFieldProps,
            },
          }}
        />
      </LocalizationProvider>
    );
  }
);
FormDatePicker.displayName = "FormDatePicker";
