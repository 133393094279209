import { createTheme, ThemeOptions } from "@mui/material/styles";
import { JsUtility } from "models/common/JsUtility";

const commonThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1390,
    },
  },
  typography: {
    fontFamily: "Karla",
    h1: {
      color: "#151319",
      fontFamily: "Sora",
      fontSize: "42px",
      lineHeight: "48px",
      fontWeight: "700",
    },
    h2: {
      color: "#151319",
      fontFamily: "Sora",
      fontSize: "42px",
      fontWeight: "700",
    },
    h3: {
      color: "#151319",
      fontFamily: "Sora",
      fontSize: "32px",
      fontWeight: "600",
      marginBottom: "10px",
    },
    h4: {
      color: "#151319",
      fontFamily: "Sora",
      fontSize: "22px",
      fontWeight: "600",
    },
    h5: {
      color: "#151319",
      fontFamily: "Sora",
      fontSize: "15px",
      fontWeight: "700",
    },
    subtitle2: {
      color: "#151319",
      fontFamily: "Karla",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "19px",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 4px #0000000F",
        },
      },
    },
  },
};

export const theme = createTheme(
  JsUtility.mergeDeep({}, commonThemeOptions, {
    palette: {
      primary: {
        main: "#F00",
      },
      secondary: {
        main: "#AAA79A",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: "#F00",
            color: "#FFF",
          },
        },
      },
    },
  })
);

export const adminTheme = createTheme(
  JsUtility.mergeDeep({}, commonThemeOptions, {
    palette: {
      primary: {
        main: "#71B9DA",
      },
      secondary: {
        main: "#6291A8",
      },
      error: {
        light: "#ffebeb",
        main: "#ef1b1b",
        dark: "#801919",
        contrastText: "#640000",
      },
      success: {
        light: "#EDF7ED",
        main: "#6de06d",
        contrastText: "#004600",
      },
      warning: {
        light: "#FFF4E5",
        main: "#ecde52",
        contrastText: "#3f3700",
      },
      info: {
        light: "#E5F2FF",
        main: "#71B9DA",
        contrastText: "#004A7F",
      },
    },
  })
);
