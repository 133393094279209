import moment, { Moment } from "moment";
import { Modify } from "models/common/JsUtility";
import slugify from "slugify";

export enum MediaTypes {
  IMAGE = "IMAGE",
  FILE = "FILE",
}

export enum MediaImageThumbSizes {
  MICRO = "_50",
  MINI = "_150",
  SMALL = "_480",
  MEDIUM = "_800",
  LARGE = "_1440",
  EXTRA_LARGE = "_1920",
}

export const MediaImageWidthSizes: {
  [key in MediaImageThumbSizes]: number;
} = {
  [MediaImageThumbSizes.MICRO]: 50,
  [MediaImageThumbSizes.MINI]: 150,
  [MediaImageThumbSizes.SMALL]: 480,
  [MediaImageThumbSizes.MEDIUM]: 800,
  [MediaImageThumbSizes.LARGE]: 1440,
  [MediaImageThumbSizes.EXTRA_LARGE]: 1920,
};

export type IMediaFe = {
  _id: string;
  key: string;
  extension: string;
  created: string;
  type?: MediaTypes;
  filename?: string;
  title?: string;
  description?: string;
};

export class MediaFe implements Modify<IMediaFe, { created: Moment }> {
  _id: string;
  key: string;
  extension: string;
  created: Moment;
  type?: MediaTypes;
  filename?: string;
  title?: string;
  description?: string;

  constructor(iMediaFe: IMediaFe) {
    if (iMediaFe) {
      Object.assign(this, iMediaFe);
      this.created = moment(iMediaFe.created);
    }
  }

  getUrlFromKeyAndExtension(
    mediaImageThumbSize?: MediaImageThumbSizes
  ): string {
    if (this.filename === undefined) {
      return `https://${process.env.NEXT_PUBLIC_AWS_MEDIA_BUCKET_NAME}.s3.${process.env.NEXT_PUBLIC_AWS_REGION}.amazonaws.com/${this.key}/original.${this.extension}`;
    }
    return `https://${process.env.NEXT_PUBLIC_AWS_MEDIA_BUCKET_NAME}.s3.${
      process.env.NEXT_PUBLIC_AWS_REGION
    }.amazonaws.com/${this.key}/${this.filename}${
      mediaImageThumbSize ? mediaImageThumbSize : ""
    }.${this.extension}`;
  }
}
