import { IStoreFe } from "models/client/StoreFe";
import LatLngLiteral = google.maps.LatLngLiteral;
import { IArticleFe } from "../../models/client/ArticleFe";

export enum StoreLocatorContextActions {
  INITIALIZE = "INITIALIZE",
  CHANGE_SELECTED_STORE = "CHANGE SELECTED STORE",
  CHANGE_CLICKED_STORE = "CHANGE CLICKED STORE",
  CHANGE_USER_STORE = "CHANGE USER STORE",
  SET_MAP_STORES = "SET MAP STORES",
  SET_LIST_STORES = "SET LIST STORES",
  SET_MAP_CENTER = "SET MAP CENTER",
  SET_MAP_ZOOM = "SET MAP ZOOM",
  SET_HORIZONTAL_WIDTH = "SET HORIZONTAL WIDTH",
  SET_STORE_ARTICLES = "SET STORE ARTICLES",
}

export interface StoreLocatorContextState {
  center: LatLngLiteral;
  horizontalWidth: number;
  zoom: number;
  iStoresMap: IStoreFe[];
  iStoresList: IStoreFe[];
  storeArticles?: IArticleFe[];
  selectedStore: IStoreFe;
  clickedStore: IStoreFe;
  userStore: IStoreFe;
}

export type StoreLocatorContextType = StoreLocatorContextState & {
  onStoreSelected: (store: IStoreFe) => void;
  onStoreClicked: (store: IStoreFe) => void;
  onNearStoreFound: (store: IStoreFe) => void;
  searchByLocation: (center: LatLngLiteral, zoom: number) => void;
  searchByUserLocation: (
    center: LatLngLiteral,
    zoom: number
  ) => Promise<IStoreFe[]>;
  searchByName: (storeName: string) => void;
  onCenterChanged: (center: LatLngLiteral) => void;
  onZoomChanged: (zoom: number) => void;
  onHorizontalWidthChanged: (horizontalWidth: number) => void;
  onUserStoreSelected: (store: IStoreFe) => void;
  setStoreArticles: (storeArticles: IArticleFe[]) => void;
  storeArticles: IArticleFe[];
  userStore: IStoreFe;
};

export interface StoreLocatorContextInitializeAction {
  payload: IStoreFe[];
}
export interface StoreLocatorContextChangeSelectedStoreAction {
  payload: IStoreFe;
}
export interface StoreLocatorContextChangeUserStoreAction {
  payload: IStoreFe;
}
export interface StoreLocatorContextStoreDefaultAction {
  payload: IStoreFe;
}
export interface StoreLocatorContextChangeClickedStoreAction {
  payload: IStoreFe;
}
export interface StoreLocatorContextSetMapStoresAction {
  payload: IStoreFe[];
}
export interface StoreLocatorContextSetListStoresAction {
  payload: IStoreFe[];
}
export interface StoreLocatorContextSetMapCenterAction {
  payload: LatLngLiteral;
}
export interface StoreLocatorContextSetMapZoomAction {
  payload: number;
}
export interface StoreLocatorContextSetHorizontalWidthAction {
  payload: number;
}
export interface StoreLocatorContextSetStoreArticlesAction {
  payload: IArticleFe[];
}
